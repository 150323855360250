import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import waitForCsrfToken from '@/Functions/waitForCsrfToken';

export const dishApi = createApi({
  reducerPath: 'dishApi',
  tagTypes: ['Dishes'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v2/',
    prepareHeaders: async (headers, { getState }) => {
      const csrfToken = await waitForCsrfToken(getState);
      if (csrfToken) {
        headers.set('X-CSRF-TOKEN', csrfToken);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getDish: builder.query({
      query: (params) => ({
        url: route('dishes.query'),
        method: 'GET',
        params: {
          'page[number]': params.page,
          'page[size]': params.size,
          'filter[name]':
            (params?.filters.name ?? '') !== ''
              ? params?.filters.name
              : undefined,
          'filter[category]':
            (params?.filters.category ?? '') !== ''
              ? params?.filters.category
              : undefined,
          'filter[database]':
            (params?.filters.database ?? '') !== ''
              ? params?.filters.database
              : undefined,
          'filter[ageRange]':
            (params?.filters.ageRange ?? '') !== ''
              ? params?.filters.ageRange
              : undefined,
          'filter[customerId]':
            (params?.filters.customerId ?? '') !== ''
              ? params?.filters.customerId
              : undefined,
        }
      }),
      providesTags: ['Dishes'],
      keepUnusedDataFor: 300
    })
  })
});

export const { useGetDishQuery } = dishApi;
